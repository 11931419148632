import "focus-visible";
import jQuery from "jquery";

window.bevinars = {
  newsletterRedirectURL: "",
  init: function () {
    bevinars.newsletterRedirectURL = window.newsletterRedirectURL;
    // newsletter signup form
    jQuery(".newsletter-signup").on("submit", bevinars.events.newsletterSubmit);
    // tab controls
    jQuery("nav.tabs").each(bevinars.tabs.init);
    jQuery("nav.tabs > div").on("click", bevinars.tabs.clickHandler);
    // carousel
    jQuery(".carousel").each(bevinars.carousel.init);
    // buy now scrolldown
    jQuery(".buy-now-scrolldown, a[href*=buy-now]").on("click", bevinars.scrolldown);
    // clickable cards
    jQuery(".block-type-events_list_block li").each(function (ix, el) {
      if (jQuery(el).find("a[role=button]").length) {
        jQuery(el)
          .addClass("clickable")
          .on("click", function (ev) {
            if (ev.target.tagName.toLowerCase() !== "a") {
              ev.preventDefault();
              window.location.href = jQuery(el).find("a[role=button]").attr("href");
            }
          });
      }
    });
    jQuery("a[href*='#subscribe'").on("click", function (ev) {
      ev.preventDefault();
      jQuery("html, body").animate({
        scrollTop: jQuery("#footer").offset().top,
      });
      jQuery("#newsletter-signup-fnamefooter").trigger("focus");
    });
    bevinars.popup.init();
    // post-event email check form
    jQuery("#post-event-email-checker-button").on("click", function () {
      if (jQuery("#post-event-email-checker-input").val().match(bevinars.regexes.email)) {
        window.location.href =
          window.location.href +
          "?email=" +
          encodeURIComponent(jQuery("#post-event-email-checker-input").val());
      } else {
        window.alert("Please enter an email address");
      }
    });
  },
  scrolldown: function (ev) {
    if (ev) {
      ev.preventDefault();
    }
    var targetY = jQuery(".buy-now-scrolldown-target").offset().top;
    jQuery("html, body").animate({
      scrollTop: targetY - 48,
    });
  },
  carousel: {
    init(ix, el) {
      jQuery(el).attr("data-carousel-count", jQuery(el).find("nav button").length);
      jQuery(el)
        .find(".scroller")
        .on("scroll", function (ev) {
          ev.preventDefault();
          var thisCarousel = jQuery(ev.target).closest(".carousel");
          bevinars.carousel.checkFades(thisCarousel);
        });
      jQuery(el)
        .find("nav button")
        .each(function (ix1, el1) {
          jQuery(el1).attr("data-carousel-nav", ix1);
          jQuery(el1).on("click", function (ev) {
            var thisCarousel = jQuery(ev.target).closest(".carousel");
            thisCarousel.attr("data-carousel-current", ix1);
            thisCarousel.find(".scroller").animate({
              scrollLeft:
                ix1 *
                  Math.round(
                    (thisCarousel.find("ul").outerWidth() -
                      thisCarousel.find(".scroller").outerWidth()) *
                      (1 / (thisCarousel.attr("data-carousel-count") - 1))
                  ) +
                "px",
            });
          });
        });
      jQuery(el).find("nav button").eq(0).trigger("click");
      bevinars.carousel.checkFades(jQuery(el));
    },
    checkFades(thisCarousel) {
      var cScroll = thisCarousel.find(".scroller")[0].scrollLeft;
      var maxScroll =
        thisCarousel.find("ul").outerWidth() - thisCarousel.find(".scroller").outerWidth();
      if (cScroll < 32) {
        thisCarousel.addClass("show-right-shadow");
        thisCarousel.removeClass("show-left-shadow");
      } else if (cScroll > maxScroll - 32) {
        thisCarousel.addClass("show-left-shadow");
        thisCarousel.removeClass("show-right-shadow");
      } else {
        thisCarousel.addClass("show-left-shadow show-right-shadow");
      }
      var cIndex = Math.floor((cScroll / maxScroll) * thisCarousel.find("button").length);
      cIndex = Math.max(0, cIndex);
      cIndex = Math.min(cIndex, thisCarousel.find("button").length - 1);
      thisCarousel.find("button").removeClass("active");
      thisCarousel.find("button").eq(cIndex).addClass("active");
    },
  },
  tabs: {
    init(ix, el) {
      jQuery("body").addClass(jQuery(el).find(".active").attr("data-tab-value"));
    },
    clickHandler(ev) {
      let thisTab = jQuery(ev.target);
      if (!thisTab.hasClass("active")) {
        thisTab.addClass("active");
        jQuery("body").addClass(thisTab.attr("data-tab-value"));
        thisTab.siblings().each(function (ix, el) {
          let otherTab = jQuery(el);
          otherTab.removeClass("active");
          jQuery("body").removeClass(otherTab.attr("data-tab-value"));
        });
      }
    },
  },
  cookies: {
    write: function (name, value, days) {
      var expires;
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },
    read: function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    },
    erase: function (name) {
      bevinars.cookies.write(name, "", -1);
    },
  },
  regexes: {
    // eslint-disable-next-line no-useless-escape
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  events: {
    newsletterSubmit: function (ev) {
      ev.preventDefault();
      var thisForm = jQuery(ev.target);
      var fname = thisForm.find("input[name=FNAME]").val();
      var lname = thisForm.find("input[name=LNAME]").val();
      var email = thisForm.find("input[name=EMAIL]").val();
      console.log("SUBMIT FORM", thisForm, email, bevinars.regexes.email);
      if (!fname) {
        window.alert("Please enter your first name.");
        thisForm.find("input.newsletter-fname-field").trigger("focus");
      } else if (!lname) {
        window.alert("Please enter your last name.");
        thisForm.find("input.newsletter-lname-field").trigger("focus");
      } else if (!bevinars.regexes.email.test(email)) {
        window.alert("Please enter a valid e-mail address.");
        thisForm.find("input.newsletter-email-field").trigger("focus");
      } else {
        thisForm.hide();
        jQuery(".form-progress").show();
        grecaptcha.ready(function () {
          grecaptcha
            .execute("6LcUgh8aAAAAALhxMCZ_Xxlvw-nPLD5W64XSZgfe", {
              action: "newsletter_subscribe",
            })
            .then(function (token) {
              console.log("RECAPTCHA", token);
              jQuery.ajax({
                url: "/wp-content/themes/bevinars-theme/grecaptcha.php",
                method: "POST",
                data: {
                  action: "newsletter_subscribe",
                  token: token,
                  EMAIL: email,
                  FNAME: fname,
                  LNAME: lname,
                },
                dataType: "json",
                complete: function (data) {
                  console.log("GR RESP", data.responseJSON);
                  if (data.responseJSON.passed) {
                    bevinars.cookies.write("newsletterPopupCompleted", "true", 365);
                    thisForm.hide();
                    jQuery(".form-progress").hide();
                    jQuery(".form-response").show();
                    setTimeout(function () {
                      if (window.location.search.indexOf("noredirect") < 0) {
                        // window.location.href = "/thanks-for-signing-up/";
                        window.location.href = bevinars.newsletterRedirectURL;
                      }
                    }, 1000);
                  } else {
                    window.alert("A server or network error has occurred, please try again later.");
                  }
                },
              });
            });
        });
      }
    },
    purchaseSingle: function () {
      setTimeout(function () {
        jQuery("button[name=add-to-cart]").trigger("click");
      }, 50);
    },
    purchaseSeries: function () {
      document.getElementById("bevinars_puchase_series").value = "true";
      bevinars.events.purchaseSingle();
    },
  },
  popup: {
    delayBeforeOpen: 20000, // milliseconds

    isOpen: false,
    currentPage: 0,
    maxScrollPos: 0,
    hasReachedTriggerPoint: false,
    initialScrollPoint: 0,
    init: function () {
      if (window.location.search.indexOf("showPopup") >= 0) {
        bevinars.cookies.erase("newsletterPopupDismissed");
        bevinars.cookies.erase("newsletterPopupCompleted");
        setTimeout(bevinars.popup.open, 1000);
      }
      if (window.location.search.indexOf("clearPopupCookies") >= 0) {
        bevinars.cookies.erase("newsletterPopupDismissed");
        bevinars.cookies.erase("newsletterPopupCompleted");
      }
      bevinars.popup.initialScrollPoint = jQuery(window).scrollTop();
      jQuery(window).on("scroll", bevinars.popup.onscroll);
      jQuery(window).on("scroll", bevinars.popup.startStillnessTimeout);
      jQuery(window).on("mousemove", bevinars.popup.startStillnessTimeout);
      jQuery(window).on("touchstart", bevinars.popup.startStillnessTimeout);
      jQuery(window).on("touchend", bevinars.popup.startStillnessTimeout);
      bevinars.popup.startStillnessTimeout();
      jQuery(".newsletter-close").click(bevinars.popup.close);
      jQuery(".newsletter-proceed").click(bevinars.popup.next);
      jQuery("a[href*=mailinglist]").each(function (ix, el) {
        // jQuery(el).removeAttr('href');
        jQuery(el).css("cursor", "pointer");
        jQuery(el).on("mouseup touchend", function () {
          bevinars.popup.open(true);
        });
      });
    },
    onscroll: function () {
      var cScroll = Math.ceil(jQuery(window).scrollTop());
      var pHeight = jQuery("body").height() - jQuery(window).height();
      bevinars.popup.maxScrollPos = Math.max(bevinars.popup.maxScrollPos, cScroll);
      if (cScroll > pHeight / 2 && cScroll > bevinars.popup.initialScrollPoint) {
        bevinars.popup.hasReachedTriggerPoint = true;
      }
      //console.log(cScroll,pHeight/2,bevinars.popup.maxScrollPos,bevinars.popup.hasReachedTriggerPoint);
      if (bevinars.popup.hasReachedTriggerPoint && cScroll < bevinars.popup.maxScrollPos - 20) {
        bevinars.popup.open();
      }
    },
    startStillnessTimeout: function () {
      clearTimeout(bevinars.popup.stillnessTimeout);
      bevinars.popup.stillnessTimeout = setTimeout(
        bevinars.popup.open,
        bevinars.popup.delayBeforeOpen
      );
    },
    reset: function () {
      jQuery("#newsletter-popup section").removeClass("show");
      jQuery("#newsletter-popup section").eq(2).find(".newsletter-text-large, h3").show();
      jQuery("#newsletter-popup .newsletter-text-large.error").remove();
    },
    open: function (force) {
      if (
        !bevinars.popup.isOpen &&
        (force ||
          (bevinars.cookies.read("newsletterPopupDismissed") != "true" &&
            bevinars.cookies.read("newsletterPopupCompleted") != "true"))
      ) {
        bevinars.popup.reset();
        bevinars.popup.isOpen = true;
        jQuery("#newsletter-popup").show();
        setTimeout(function () {
          jQuery("#newsletter-popup").addClass("show");
        }, 25);
        setTimeout(function () {
          jQuery("#newsletter-popup section").eq(0).css("display", "flex");
        }, 500);
        setTimeout(function () {
          jQuery("#newsletter-popup section").eq(0).addClass("show");
        }, 525);
      }
    },
    close: function () {
      if (bevinars.popup.isOpen) {
        bevinars.cookies.write("newsletterPopupDismissed", "true", false);
        bevinars.popup.isOpen = false;
        jQuery("#newsletter-popup").removeClass("show");
        setTimeout(function () {
          jQuery("#newsletter-popup").css("display", "none");
        }, 525);
      }
    },
    next: function () {
      jQuery("#newsletter-popup section.show").removeClass("show");
      setTimeout(function () {
        jQuery("#newsletter-popup section").eq(bevinars.popup.currentPage).css("display", "none");
        bevinars.popup.currentPage++;
        jQuery("#newsletter-popup section").eq(bevinars.popup.currentPage).css("display", "flex");
        setTimeout(function () {
          jQuery("#newsletter-popup section").eq(bevinars.popup.currentPage).addClass("show");
        }, 25);
      }, 500);
    },
  },
};
document.addEventListener("DOMContentLoaded", bevinars.init);
